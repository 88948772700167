// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap/js/src/dropdown" // TODO put bootstrap plugin imports in a separate file
import "bootstrap/js/src/collapse"
import "datejs"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

navigator.inferLanguage = function() {
    let languageCode = (navigator.languages && navigator.languages.length) ? navigator.languages[0]
     : navigator.userLanguage || navigator.language || navigator.browserLanguage || (new Intl.NumberFormat()).resolvedOptions().locale || 'en-US';
    return languageCode.split('-', ).map(function (val, i) { return i == 0 ? val : val.toUpperCase(); }).join('-')
}
Date.i18n.setLanguage(navigator.inferLanguage());
Date.prototype.toCustomLocalisedString = function() {
    return this.toString(Date.CultureInfo.formatPatterns.shortDate) + ' ' + this.toString(Date.CultureInfo.formatPatterns.longTime);
}

// XXX: Work around to access jQuery in unobtrusive javascript and some callbacks.
// Need to work out why jQuery is not properly accessible in unobtrusive javascript
document.$ = $;
document.jq = $;
